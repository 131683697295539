import React from "react";
import styled from "styled-components";

const StyledAboutPage = styled.div`
  flex: 1;
  border: solid 2px #dc73fe;
  margin: 1px;
  padding: 1px;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.span`
  font-size: 6rem;
  font-weight: 600;
  color: var(--sub);
  margin-bottom: 6rem;
`;

const PinkHeader = styled(Header)`
  color: var(--primary);
  margin: 0 3rem;
`;

const Paragraph = styled.p`
  font-size: 2rem;
  font-weight: 500;
  color: #ff61b7;
  margin-bottom: 2rem;
`;

const AboutPage = () => {
  return (
    <StyledAboutPage>
      <Header>
        <PinkHeader>=^_^=</PinkHeader> About LoFi Girls{" "}
        <PinkHeader>=^_^=</PinkHeader>
      </Header>
      <Paragraph>
        LoFi Girls is an NFT project with the goal of raising money for charity
        that provides medicine to prevent malaria. 10,000 Girls will be on sale,
        each one completely unique and generated from the assets of our amazing
        artists! The girls will cost 0.03 ETH each to mint plus any gas costs.
      </Paragraph>
      <Paragraph>
        The Girls will be on sale for 4 days before the main sale comes to an
        end. The Girls will be revealed at the end of the 4 days or when all of
        the Girls have been minted, whichever comes first. After the end of the
        4 days you will still be able to purchase a girl, and the girl you
        received will be revealed after being minted.
      </Paragraph>
      <Paragraph>
        All Girls are ERC-721 Token NFTs stored on the Ethereum Blockchain. The
        token data for the girls will be through our API innitially, but will be
        moved to being stored fully on IPFS after all Girls have been revealed.
      </Paragraph>
      <Paragraph>
        The distribution of LoFi girls is completely fair for everyone. There
        will be no Girls held for the development team, the only way to mint one
        is to mint through the smart contract.
      </Paragraph>
      <Paragraph>
        There will be no OpenSea trading fee. And the owner of each girl will
        have the full legal rights to use them in any way they wish.
      </Paragraph>
      <Paragraph>
        LoFi Girls has a rug-proof mechanic that ensures that all of the ETH
        from the sale can go to the charity and only the charity. This means you
        can confidently mint LoFi Girls knowing that the money will go to a
        great cause.
      </Paragraph>
    </StyledAboutPage>
  );
};

export default AboutPage;
