import React from "react";
import styled from "styled-components";
import buttonBg from "../assets/ui/pink-button.png";

const StyledButton = styled.button`
  position: relative;
  margin-bottom: 2rem;
`;

const Background = styled.img`
  height: 8.3rem;
  filter: saturate(1.1);
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: 900;
  font-size: 3rem;
  text-shadow: 0 0 15px white, 0 0 15px white, 0 0 15px white, 0 0 15px white,
    0 0 15px white, 0 0 15px white, 0 0 15px white, 0 0 15px white;
  color: #de71fd;
  text-transform: capitalize;
`;

type Props = {
  label: string;
  click: () => void;
};

const Button = (props: Props) => {
  return (
    <StyledButton onClick={() => props.click()}>
      <Background src={buttonBg} />
      <Content>{props.label}</Content>
    </StyledButton>
  );
};

export default Button;
