import React from "react";
import styled from "styled-components";
import Button from "../components/Button";
import { openAndFocusWindow } from "../lib/browser";

const StyledCharityPage = styled.div`
  flex: 1;
  border: solid 2px #dc73fe;
  margin: 1px;
  padding: 1px;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 6rem;
  }
`;

const Header = styled.span`
  font-size: 6rem;
  font-weight: 600;
  color: var(--sub);
  margin-bottom: 6rem;
`;

const PinkHeader = styled(Header)`
  color: var(--primary);
  margin: 0 3rem;
`;

const Paragraph = styled.p`
  font-size: 2rem;
  font-weight: 500;
  color: #ff61b7;
  margin-bottom: 2rem;
  width: 100%;
`;

const CharityPage = () => {
  return (
    <StyledCharityPage>
      <Header>
        <PinkHeader>/ᐠ｡ꞈ｡ᐟ\</PinkHeader> Charity{" "}
        <PinkHeader>/ᐠ｡ꞈ｡ᐟ\</PinkHeader>
      </Header>
      <Paragraph>
        LoFi Girls is proud to be working with Give Well, a crypto friendly
        charity that focuses on high-impact, cost-effective charities — backed
        by evidence and analysis.
      </Paragraph>
      <Paragraph>
        All funds raised from LoFi Girls will go to the Malaria Consortum, a
        seasonal malaria chemoprovention program.
      </Paragraph>
      <Paragraph>
        Malaria kills over 400,000 people annually, mostly children under 5 in
        sub-Saharan Africa. Seasonal malaria chemoprevention is preventive
        medicine that saves children’s lives. It is given during the four months
        of the year when malaria infection rates are especially high.
      </Paragraph>
      <Paragraph>
        The impact of the Malaria Consortum charity has been evaluated as
        Exceptionally Strong. It is estimated that $3,000 - $5,000 will save a
        life. This means that minting 70 LoFi Girls will provide enough funds to
        save a life!
      </Paragraph>
      <Button
        label="GiveWell"
        click={() =>
          openAndFocusWindow("https://www.givewell.org/charities/top-charities")
        }
      />
    </StyledCharityPage>
  );
};

export default CharityPage;
