import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --main: #5268A1;
        --sub: #44c4fe;
        --primary: #c93afe;
        --bg: #F8F2F2;
    }

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: 10px;
      cursor: url('https://cur.cursors-4u.net/nature/nat-11/nat1019.cur'), auto !important;
    }
  
    button {
      background: none;
      border: none;
      outline: none;
    }

    a{
        text-decoration: none;
    }
  `;

const GlobalStyles = (): JSX.Element => {
  return <GlobalStyle />;
};

export default GlobalStyles;
