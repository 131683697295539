import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [1, 4],
});

export const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    1: process.env.RPC_URL_1 as string,
    4: process.env.RPC_URL_4 as string,
  },
  qrcode: true,
  pollingInterval: 12000,
});
