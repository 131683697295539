import { Contract } from "@ethersproject/contracts";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import border from "../assets/ui/border.png";
import { GIRLS_ADDRESS, PRICE } from "../lib/constants";
import girlsAbi from "../contracts/LoFiGirls.json";
import { useWeb3React } from "@web3-react/core";
import { bigNumberToFloat } from "../lib/numeric";
import { getEthPrice } from "../lib/coingecko";

const StyledRightBar = styled.div`
  width: 20rem;
  border: solid 2px #ff9d52;
  margin: 1px;
  padding: 2rem;
`;

const BorderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

const Border = styled.img`
  width: 100%;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Number = styled.div`
  color: #e982ff;
  font-size: 2.7rem;
  font-weight: 800;
`;

const Label = styled.div`
  color: #45c3fe;
  font-size: 1.7rem;
  font-weight: 800;
`;

const RightBar = () => {
  const [minted, setMinted] = useState(0);
  const [price, setPrice] = useState(0);
  const { library } = useWeb3React();

  const init = async () => {
    if (!library) return;
    const girls = new Contract(GIRLS_ADDRESS, girlsAbi, library.getSigner());
    const minted = await girls._minted();
    setMinted(bigNumberToFloat(minted, 0));

    const _price = await getEthPrice();
    console.log(_price);
    setPrice(_price);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [library]);

  return (
    <StyledRightBar>
      <BorderContainer>
        <Border src={border} />
        <Content>
          <Number>{`$${Math.round(
            minted * PRICE * price
          ).toLocaleString()}`}</Number>
          <Label>Donated</Label>
        </Content>
      </BorderContainer>
      <BorderContainer>
        <Border src={border} />
        <Content>
          <Number>{`${(minted / 100).toFixed(2)}%`}</Number>
          <Label>Minted</Label>
        </Content>
      </BorderContainer>
      <BorderContainer>
        <Border src={border} />
        <Content>
          <Number>{`${(minted * PRICE * price * (1 / 4000)).toFixed(
            2
          )}`}</Number>
          <Label>Lives Saved</Label>
        </Content>
      </BorderContainer>
    </StyledRightBar>
  );
};

export default RightBar;
