import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import styled from "styled-components";
import i01 from "../assets/examples/01.png";
import i02 from "../assets/examples/02.png";
import i03 from "../assets/examples/03.png";
import i04 from "../assets/examples/04.png";
import Button from "../components/Button";
import Popup from "../components/Popup";
import { injectedConnector, walletConnectConnector } from "../lib/connectors";
import girlsAbi from "../contracts/LoFiGirls.json";
import { GIRLS_ADDRESS } from "../lib/constants";
import { Contract, ethers } from "ethers";

const StyledLandingPage = styled.div`
  flex: 1;
  border: solid 2px #dc73fe;
  margin: 1px;
  padding: 1px;
  display: flex;
  padding: 6rem;
`;

const Left = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 2rem;
`;

const Text = styled.h2`
  font-size: 3rem;
  color: var(--primary);
  font-weight: 600;
  font-family: "Times New Roman", Times, serif;
`;

const BuyInputContainer = styled.div`
  display: flex;
`;

const Input = styled.input`
  height: 8.3rem;
  margin-right: 2rem;
  width: 20rem;
  font-size: 3rem;
  color: var(--primary);
  padding: 3rem;

  ::placeholder {
    color: rgba(201, 58, 254, 0.5);
  }
`;

const Right = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
`;

const Examples = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
`;

const Image = styled.img`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 4rem;
`;

const LandingPage = () => {
  const [loading, setLoading] = useState(false);
  const { account, library, activate } = useWeb3React();
  const [connecting, setConnecting] = useState(false);
  const [amount, setAmount] = useState("");

  const connectMetamask = async () => {
    await activate(injectedConnector);
    setConnecting(false);
  };

  const connectWalletConnect = async () => {
    await activate(walletConnectConnector);
    setConnecting(false);
  };

  const buyGirls = async () => {
    setLoading(true);
    const girls = new Contract(GIRLS_ADDRESS, girlsAbi, library.getSigner());
    await girls.mint(Number(amount), {
      value: ethers.utils.parseEther((0.008 * Number(amount)).toString()),
    });
    setLoading(false);
  };

  return (
    <StyledLandingPage>
      <Left>
        <Text>
          LoFi Girls is an NFT collection of 10,000 unique and generative
          artworks. Of the proceeds raised from LoFi Girls, 100% will go to
          charity to buy medicine to prevent malaria. The artwork is created by
          all women artists. The mint price per NFT is 0.03 ETH.
        </Text>
        <BuyInputContainer>
          <Input
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e: any) => setAmount(e.target.value)}
          />
          <Button
            label={loading ? "loading" : account ? "buy" : "connect"}
            click={() => {
              if (loading) return;
              if (!account) setConnecting(true);
              else buyGirls();
            }}
          />
        </BuyInputContainer>
      </Left>
      <Right>
        <Examples>
          <Image src={i01} />
          <Image src={i02} />
          <Image src={i03} />
          <Image src={i04} />
        </Examples>
      </Right>
      <Popup
        show={connecting}
        close={() => setConnecting(false)}
        header="Select Wallet"
        content={
          <ButtonContainer>
            <Button label="metamask" click={() => connectMetamask()} />
            <Button
              label="walletconnect"
              click={() => connectWalletConnect()}
            />
          </ButtonContainer>
        }
      />
    </StyledLandingPage>
  );
};

export default LandingPage;
