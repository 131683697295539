import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";

const StyledHeader = styled.div`
  width: 100%;
  height: 10rem;
  border: solid 2px #ff61b7;
  margin: 1px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 100%;
`;

const Header = () => {
  return (
    <StyledHeader>
      <Logo src={logo} />
    </StyledHeader>
  );
};

export default Header;
