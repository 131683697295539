import React from "react";
import styled from "styled-components";
import Button from "../components/Button";
import { openAndFocusWindow } from "../lib/browser";

const StyledRugProofPage = styled.div`
  flex: 1;
  border: solid 2px #dc73fe;
  margin: 1px;
  padding: 1px;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.span`
  font-size: 6rem;
  font-weight: 600;
  color: var(--sub);
  margin-bottom: 6rem;
`;

const PinkHeader = styled(Header)`
  color: var(--primary);
  margin: 0 3rem;
`;

const Paragraph = styled.p`
  font-size: 2rem;
  font-weight: 500;
  color: #ff61b7;
  margin-bottom: 2rem;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 6rem;

  button {
    margin: 0 3rem;
  }
`;

const RugProofPage = () => {
  return (
    <StyledRugProofPage>
      <Header>
        <PinkHeader>ʕ •ᴥ•ʔ</PinkHeader>Rug-Proof<PinkHeader>ʕ •ᴥ•ʔ</PinkHeader>
      </Header>
      <Paragraph>
        We beleive that it is important for the NFT community to not have to
        trust the project owners to fulfull their promises. And that instead it
        should be enforced through the smart contracts.
      </Paragraph>
      <Paragraph>
        That is why for LoFi Girls, we have setup the Contract to be Rug-Proof.
        In the contract there is only one way for the ETH to be withdrawn, and
        that is to a hard coded wallet address of
        0x7cF2eBb5Ca55A8bd671A020F8BDbAF07f60F26C1
      </Paragraph>
      <Paragraph>
        This wallet address belongs to the Give Well charity which can be
        verified by visiting their site under Cryptocurrency Walets.
      </Paragraph>
      <Paragraph>
        The code for the smart contract is verified on Etherscan, where you can
        view the hard coded wallet address and the only withdraw function that
        sends directly to it.
      </Paragraph>
      <ButtonContainer>
        <Button
          label="Give Well"
          click={() =>
            openAndFocusWindow(
              "https://www.givewell.org/about/donate/cryptocurrency"
            )
          }
        />
        <Button label="Contract" click={() => openAndFocusWindow("")} />
      </ButtonContainer>
    </StyledRugProofPage>
  );
};

export default RugProofPage;
