import React from "react";
import styled from "styled-components";
import NavItem from "./NavItem";

const StyledLeftBar = styled.div`
  width: 20rem;
  border: solid 2px #48b4ff;
  margin: 1px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const LeftBar = () => {
  return (
    <StyledLeftBar>
      <NavItem label="home" route="/" />
      <NavItem label="about" route="/about" />
      <NavItem label="charity" route="/charity" />
      <NavItem label="rug-proof" route="/rugproof" />
    </StyledLeftBar>
  );
};

export default LeftBar;
