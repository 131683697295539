import React from "react";
import styled from "styled-components";
import { ethers } from "ethers";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LeftBar from "./components/LeftBar";
import RightBar from "./components/RightBar";
import LandingPage from "./pages/LandingPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import AboutPage from "./pages/AboutPage";
import CharityPage from "./pages/CharityPage";
import RugProofPage from "./pages/RugproofPage";

const StyledApp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Row = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
`;

const App = () => {
  const getLibrary = (provider: any, connector: any): any => {
    return new ethers.providers.Web3Provider(provider);
  };

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <StyledApp>
        <Router>
          <Header />
          <Row>
            <LeftBar />
            <Switch>
              <Route path="/about">
                <AboutPage />
              </Route>
              <Route path="/charity">
                <CharityPage />
              </Route>
              <Route path="/rugproof">
                <RugProofPage />
              </Route>
              <Route path="/">
                <LandingPage />
              </Route>
            </Switch>
            <RightBar />
          </Row>
          <Footer />
        </Router>
      </StyledApp>
    </Web3ReactProvider>
  );
};

export default App;
