import React from "react";
import styled from "styled-components";

const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  background-color: rgba(0, 0, 0, 0.5);
`;

const Content = styled.div`
  position: relative;
  background-color: white;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 2px #dc73fe;
  width: 50rem;
`;

const Exit = styled.div`
  font-size: 2rem;
  font-weight: 800;
  position: absolute;
  top: 2rem;
  right: 3rem;
  height: 1rem;
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: var(--sub);
`;

const Header = styled.div`
  font-size: 3rem;
  color: var(--primary);
  font-weight: 800;
`;

type Props = {
  show: boolean;
  close: () => void;
  header: string;
  content?: JSX.Element;
};

const Popup = ({ show, close, header, content }: Props) => {
  if (!show) return null;

  return (
    <StyledPopup>
      <Background />
      <Content>
        <Exit onClick={() => close()}>exit</Exit>
        <Header>{header}</Header>
        {content}
      </Content>
    </StyledPopup>
  );
};

export default Popup;
