import React from "react";
import styled from "styled-components";
import buttonBg from "../assets/ui/button.png";
import { useHistory } from "react-router";

const StyledNavItem = styled.button`
  position: relative;
  margin-bottom: 2rem;
`;

const Background = styled.img`
  width: 100%;
  filter: saturate(1.1);
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: 900;
  font-size: 3rem;
  text-shadow: 0 0 15px white, 0 0 15px white, 0 0 15px white, 0 0 15px white,
    0 0 15px white, 0 0 15px white, 0 0 15px white, 0 0 15px white;
  color: #27c0ff;
  text-transform: capitalize;
`;

type Props = {
  label: string;
  route: string;
};

const NavItem = (props: Props) => {
  const history = useHistory();

  return (
    <StyledNavItem onClick={() => history.push(props.route)}>
      <Background src={buttonBg} />
      <Content>{props.label}</Content>
    </StyledNavItem>
  );
};

export default NavItem;
