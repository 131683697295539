import { BigNumber } from "ethers";

const flooredLog = (value: BigNumber, base: number = 10): number => {
  let result = 0;
  while (value.gt(base)) {
    result++;
    value = value.div(base);
  }
  return result;
};

export const bigNumberToFloat = (
  value: BigNumber,
  decimals: number = 16,
  significantDigits: number = 5
): number => {
  const log = flooredLog(value);
  const decimalsScale = Math.min(
    Math.max(0, log - significantDigits + 1),
    decimals
  );
  const rounded = value.div(BigNumber.from(10).pow(decimalsScale)).toNumber();
  return rounded / Math.pow(10, Math.max(0, decimals - decimalsScale));
};
