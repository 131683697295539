import React from "react";
import styled from "styled-components";
import { openAndFocusWindow } from "../lib/browser";

type LinkType = {
  label: string;
  link: string;
};

const links: LinkType[] = [
  {
    label: "OpenSea",
    link: "",
  },
  {
    label: "Twitter",
    link: "",
  },
  {
    label: "Discord",
    link: "",
  },
  {
    label: "Contract",
    link: "",
  },
];

const StyledFooter = styled.div`
  height: 5rem;
  border: solid 2px #70e78c;
  margin: 1px;
  padding: 1px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Link = styled.div`
  color: #1301e8;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0 1rem;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Content>
        {links.map((link: LinkType) => (
          <Link onClick={() => openAndFocusWindow(link.link)}>
            {link.label}
          </Link>
        ))}
      </Content>
    </StyledFooter>
  );
};

export default Footer;
